@import '../../common/common.scss';

.announcement {
  grid-area: announcement;
}

.announcement-lines {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  gap: 8px;

  :nth-child(1) {
    font-size: larger;
  }

  :last-child {
    font-style: italic;
    margin-top: 10px;
  }
}
